.small-box{
    max-width: 900px;
    align-items: center;
    padding: 2rem;
}

.mySection{
    padding: 2rem;
    display:grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    grid-gap: 20px;
    text-decoration: none;
    transition: transform .35s,-webkit-transform .35s;
}

.small-padding{
    padding:1rem;
}
.small-margin{
    margin:2rem;
}

.icon{
    height:auto;
    width:4rem;
}

.headshot{
    border-radius: 50%;
    height: 16rem;
}

.space-buttons{
    margin: 0.5rem;
   
}
.btn-lg{
    width:10rem;
}