:root{
    --primary:#fff;
}

.customBtn{
    padding: 8px 20px;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
}

.customBtn1:hover{
    padding: 8px 20px;
    transition: all 0.3s ease-out;
    background: #fff;
    color: black;
    transition: 250ms;
}

.customBtn-primary{
    background-color: var(--primary);
}

.customBtn-outline{
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border-radius: 4px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.customBtn-medium{
padding: 8px 20px;
border-radius: 4px;
font-size: 18px;
color: #fff;
}

.customBtn-large{
padding: 12px 26px;
border-radius: 4px;
font-size: 20px;
color: #fff;
}